//@ts-ignore
import { decodedJwt, tokenApi } from "@enerbit/base";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as singleSpa from "single-spa";
import { LoginState } from "../../models/login";
import { DataFromLogin } from "../../models/login";
import { StateStorage } from "../../models/stateStorage";

export const onAuth = createAsyncThunk(
  "login/onAuth",
  async (dataFromLogin: DataFromLogin, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {
      const { email, password } = dataFromLogin;
      const { data } = await tokenApi.post("/auth/token/", {
        username: email,
        password: password,
      });
      localStorage.setItem(
        "authInfo",
        JSON.stringify({ ...data, email: email })
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  }
);

const initialState: LoginState = {
  isRequestingSignIn: false,
  hasError: false,
  isSignInSucceed: false,
  isPasswordObscure: false,
  errorMessage: "",
};

export const loginSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setResetVars: (state) => {
      state.hasError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(onAuth.pending.type, (state) => {
        state.isRequestingSignIn = true;
      })
      .addCase(onAuth.fulfilled.type, (state) => {
        const isTokenExpired = decodedJwt();
        if (!(isTokenExpired?.role === "contratista")) {
          if (localStorage.getItem("saveURL")) {
            singleSpa.navigateToUrl(`/#${localStorage.getItem("saveURL")}`);
          } else {
            singleSpa.navigateToUrl("/#/search-bitoria");
          }
        } else {
          singleSpa.navigateToUrl("/#/users-companies");
        }
        state.isRequestingSignIn = false;
      })
      .addCase(onAuth.rejected.type, (state, action: any) => {
        console.log(action.payload);
        if (action.payload === 401)
          state.errorMessage = "Credenciales inválidas, inténtelo nuevamente";
        if (action.payload === 403)
          state.errorMessage =
            "No estás autorizado para ingresar a esta aplicación";
        state.isRequestingSignIn = false;
        state.hasError = true;
      });
  },
});

export const { setResetVars } = loginSlice.actions;
export const isRequestingSignIn = (state: StateStorage) =>
  state.auth.isRequestingSignIn;
export const hasError = (state: StateStorage) => state.auth.hasError;
export const errorMessage = (state: StateStorage) => state.auth.errorMessage;

export default loginSlice.reducer;
