import {
  ThemeConfig,
  initEnviroment
} from "@enerbit/base";
import { Provider } from "react-redux";
import "./assets/css/login.scss";
import Login from "./pages/login/Login";
import { store } from "./store/store";

initEnviroment({
  baseUrl: process.env.REACT_APP_BASE_URL
})

export default function Root() {
  return (
    <Provider store={store}>
      <ThemeConfig>
        <Login />
      </ThemeConfig>
    </Provider>
  )
}
