import {
  Box,
  CustomModal,
  Link,
  LoadingButton,
  TextField,
  enerbitColors,
  theme,
} from "@enerbit/base";
import { forwardRef, useImperativeHandle, useRef } from "react";
import "../../../assets/css/login.scss";
import type { CustomModalTypes } from "../../../models/login";

const ForgotPasswordModal = forwardRef((props, ref) => {
	const forgotPassword = useRef<CustomModalTypes>(null);

	useImperativeHandle(ref, () => ({
		changeModal() {
			if (forgotPassword.current) {
				forgotPassword.current.changeModal();
			}
		},
	}));

  return (
    <CustomModal
      ref={forgotPassword}
      dialogContent={
        <>
          <Box className="Dialog-close-box" color={theme.palette.primary.main}>
            Ingresa tu correo eléctronico
          </Box>
          <Box
            className="TextField-without-border-radius"
            sx={{ width: "100% !important", paddingBottom: "10px" }}
          >
            <TextField
              autoFocus
              margin="dense"
              id="outlined-required"
              label="Correo"
              type="email"
              sx={{ "& fieldset": { borderRadius: "14px" } }}
              fullWidth
            />
          </Box>
          <Box
            sx={{ paddingBottom: "10px" }}
            color={enerbitColors.neutral.main}
          >
            ¿No tienes correo registrado?Cómunicate con ...
            <Link href="#" underline="always">
              Aquí
            </Link>
          </Box>
        </>
      }
      dialogActions={
        <LoadingButton
          variant="contained"
          color="warning"
          size="small"
          type="submit"
          className="Loading-button"
          sx={{ width: "93%" }}
          loading={false}
        >
          <Box style={{ fontWeight: "bold", margin: 0 }}>Enviar</Box>
        </LoadingButton>
      }
    />
  );
});

export default ForgotPasswordModal;
