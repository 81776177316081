import {
	Box,
	Button,
	CustomAlert,
	CustomModal,
	Grid,
	IconButton,
	InputLabel,
	LoadingButton,
	TextField,
	VisibilityIcon,
	VisibilityOffIcon,
	decodedJwt,
	theme,
	useFormik,
	yup,
} from "@enerbit/base";
import { useDispatch, useSelector } from "react-redux";
import * as singleSpa from "single-spa";
import "../../../assets/css/login.scss";
import { useEffect, useRef, useState } from "react";
import imgLogo from "../../../assets/img/login.png";
import {
	errorMessage,
	hasError,
	isRequestingSignIn,
	onAuth,
	setResetVars,
} from "../../../features/login/loginSlice";
import type { CustomModalTypes, DataFromLogin } from "../../../models/login";
import type { AppDispatch } from "../../../store/store";
import ForgotPasswordModal from "../../forgotPassword/components/ForgotPasswordModal";

const validationSchema = yup.object({
	email: yup
		.string()
		.email("Correo no es válido")
		.required("Correo es requerido"),
	password: yup.string().required("Contraseña es requerida"),
});

const LoginForm = () => {
	const isLoading = useSelector(isRequestingSignIn);
	const hasErrorLogin = useSelector(hasError);
	const errorMessageLogin = useSelector(errorMessage);

	const dispatch = useDispatch<AppDispatch>();
	const [seePass, setseePass] = useState(false);

	const initialValues: DataFromLogin = {
		email: "",
		password: "",
	};

	const formik = useFormik({
		initialValues,
		validationSchema: validationSchema,
		onSubmit: (values: DataFromLogin) => dispatch(onAuth(values)),
	});

	const iconAdorment = (
		<IconButton onClick={() => setseePass(!seePass)}>
			{seePass ? <VisibilityIcon /> : <VisibilityOffIcon />}
		</IconButton>
	);

	const forgotPassword = useRef<CustomModalTypes>(null);

	const callForgetPassword = () => {
		if (forgotPassword.current) {
			forgotPassword.current.changeModal();
		}
	};

	return (
		<Box>
			<Grid container spacing={2} sx={{ flexGrow: 1 }}>
				<Grid className="Login-img-grid" item xs={12} md={6}>
					<img
						width="40%"
						style={{ padding: "20px 20px" }}
						src={imgLogo}
						alt="login"
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<form onSubmit={formik.handleSubmit}>
						<Box className="Box-grid-center">
							<Box className="TextField-without-border-radius TextField-width-login">
								<InputLabel shrink className="Input-label">
									Correo electrónico
								</InputLabel>
								<TextField
									fullWidth
									name="email"
									value={formik.values.email}
									onChange={formik.handleChange}
									error={formik.touched.email && Boolean(formik.errors.email)}
									helperText={formik.touched.email && formik.errors.email}
									InputLabelProps={{
										shrink: true,
									}}
									sx={{
										borderRadius: "14px",
										"& fieldset": { borderRadius: "14px" },
									}}
								/>
							</Box>
							<Box className="TextField-without-border-radius TextField-width-login">
								<InputLabel shrink className="Input-label">
									Contraseña
								</InputLabel>
								<TextField
									fullWidth
									name="password"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										endAdornment: iconAdorment,
									}}
									sx={{
										borderRadius: "14px",
										"& fieldset": { borderRadius: "14px" },
									}}
									type={seePass ? "input" : "password"}
									value={formik.values.password}
									onChange={formik.handleChange}
									error={
										formik.touched.password && Boolean(formik.errors.password)
									}
									helperText={formik.touched.password && formik.errors.password}
								/>
							</Box>
							{hasErrorLogin && (
								<CustomAlert
									onClose={() => dispatch(setResetVars())}
									sx={{
										width: "60%",
										marginBottom: "20px",
										backgroundColor: "#fee4e2 !important",
									}}
									severity="error"
									text={errorMessageLogin}
								/>
							)}
							<LoadingButton
								variant="contained"
								color="warning"
								size="small"
								type="submit"
								className="Loading-button"
								sx={{ width: "60%", mx: "auto" }}
								loading={isLoading}
							>
								<Box style={{ fontWeight: "bold", margin: 0 }}>
									Iniciar sesión
								</Box>
							</LoadingButton>
							<Box>
								<Button
									color="primary"
									onClick={callForgetPassword}
									className="Forgot-password"
								>
									Olvidé mi contraseña
								</Button>
							</Box>
							<ForgotPasswordModal ref={forgotPassword} />
						</Box>
					</form>
				</Grid>
			</Grid>
		</Box>
	);
};

export default LoginForm;
